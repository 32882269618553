<template>
    <div style="min-height:100vh;background:#ffffff">
        <!-- <div style="width:100%;height:25px;"></div> -->
       <div class="img_fa">
           <div class="img_fa_cd" v-for="(item,index) in img_url" :key="index" ><img style="width:100%;height:100%" @click="lookImg(index)" :src="item||morenurl" alt=""></div>
       </div>
        <!-- 底部 -->
        <div class="footer-bottom"></div>
        <!-- <div class="footer">
            <div @click="save" :style="is_click ? 'background:#aaa' : ''">确认</div>
        </div> -->
        <!-- 弹窗 -->
        <div class="bottom_back_fiexd">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
        <div class="cancl_tan" v-if="my_lizhi">
            <div class="cancl_tan_on">
                <div class="cancl_tan_on_title">修改基本信息</div>
                <div class="cancl_tan_on_cont">
                    <div class="cancl_tan_on_cont_ont">识别信息中{{msg}}与原有信息不一致,是否确认修改</div>
                </div>
                <div class="cancl_tan_on_bont">
                    <div class="cancl_tan_on_bont_lt" @click="cancle_no">取消</div>
                    <div class="cancl_tan_on_bont_rt" @click="cancle_ok">确认</div>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
    </div>
</template>
<script>
import * as imageConversion from 'image-conversion'
import '@/assets/css/index.css'
import { ImagePreview, Toast } from "vant";
// import { send_sms_get } from "@/api/login";
// import { getInfo, postInfo, mobile_bind } from "@/api/home";
export default {
    data() {
        return {
            time: 0, // 倒计时
            details: {},
            my_lizhi:false,
            mobile: '', // 手机号码
            code: '', // 验证码
            type:1,
            card_num:'',
            jobseeker_name:'',
            isshangc:1,
            data_obj:{},
            detaills:{},
            up_obj:{},
            img_url:[],
            morenurl:require('../../assets/img/morencaifeng.png'),
            msg:'',
            url:'',
            is_form: 'home', // home，apply: 从home，apply过来的要请求接口，info：过来的不需要请求接口
            is_click: false
        }
    },
    created(){
        this.img_url = JSON.parse(this.$route.params.type)
    },
    mounted() {
        window.scrollTo(0, 0)
        this.$store.commit("hideTabbar");
        this.$store.commit("getWxSDK");
        
    },
    methods: {
                // 查看全部图片
        lookImg(key) {
            ImagePreview({
                images: this.img_url,
                startPosition: key,
            });
        },
        gobanck(){
            this.$router.go(-1);
        },
        getimgidcard(e){
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            imageConversion.compressAccurately(e.file, 100).then(res => {
                let img = new window.File([res], 'cs.png', { type: 'image/png' },);
                this.getBase64(img).then(res => {
                    var image = res
                    let params = new FormData()
                    params.append('image', res)
                    params.append('id_card_side', 'front')
                    params.append('access_token', this.bd_token)
                    this.axios.defaults.headers["Content-Type"] = 'application/x-www-form-urlencoded'
                    this.axios.post('https://aip.baidubce.com/rest/2.0/ocr/v1/idcard', params).then(res => {
                        if (res.data.idcard_number_type < 0) {
                            Toast.clear()
                            Toast('请上传正确的身份证图片')
                            return
                        }
                        if (res.data.words_result['出生']) {
                            this.Request('card/img/upload','post',{ img: this.url.split(',')[1], img_type: 'id_card' }).then(res2 => {
                                this.$store.commit('idcardURL_add', { index: 0, item: res2.data[0] })
                            })
                            if(!!res.data.words_result['姓名'].words == false || !!res.data.words_result['性别'].words == false || !!res.data.words_result['民族'].words == false || !!res.data.words_result['住址'].words == false || !!res.data.words_result['出生'].words == false || !!res.data.words_result['公民身份号码'].words == false){
                                Toast.clear()
                                Toast('请上传正确的身份证人像面图片')
                                return
                            }else{
                                Toast.clear()
                                Toast.success('上传成功')
                            }
                            var birthday = res.data.words_result['出生'].words
                            let data = {
                                'jobseeker_name': res.data.words_result['姓名'].words,
                                'sex': res.data.words_result['性别'].words,
                                'nation': res.data.words_result['民族'].words,
                                'native_place': res.data.words_result['住址'].words,
                                'birthyear': birthday.substring(0, 4) + '-' + birthday.substring(4, 6) + '-' + birthday.substring(6, 8),
                                'jobseeker_id_card': res.data.words_result['公民身份号码'].words,
                                is_card_true: false
                            }
                            this.isshangc =2;
                            this.data_obj = data
                            this.card_num = res.data.words_result['公民身份号码'].words
                            // this.$store.commit('update', data)
                             this.url = image
                            // this.isflag1 = false
                        }

                    })
                    Toast.clear()
                })
            });
        },
        save(){
            if(this.type==1){
                if(!!this.jobseeker_name==false){
                    Toast('请输入姓名');
                    return
                }
                let data={
                    jobseeker_name:this.jobseeker_name
                }
                this.Request('client/mine/info', 'post',data,true,false).then(res => {
                                if (res.status == 0) {
                                    //this.getInfo()
                                    this.$router.go(-1)
                                }
                                this.is_click = false
                            })
            }else{
                    if(!!this.card_num==false){
                        Toast('请输入身份证号');
                        return
                    }
                
                var id=/^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
                    if (id.test(this.card_num)===true){
                    if(!!this.card_num==false){
                            Toast('请输入正确的身份证号码');
                            return
                    } 
                }
                let ii = 1;
                if(this.data_obj.jobseeker_name&&this.detaills.jobseeker_name&&this.detaills.jobseeker_name!=this.data_obj.jobseeker_name){
                    this.up_obj.jobseeker_name = this.data_obj.jobseeker_name;
                    this.msg = this.msg+'姓名'+' '
                    ii = 2
                }
                if(this.data_obj.sex&&this.detaills.sex&&this.detaills.sex!=this.data_obj.sex){
                    this.up_obj.sex = this.data_obj.sex;
                    this.msg = this.msg+'性别'+' ';
                    ii = 2
                }
                if(this.data_obj.nation&&this.detaills.nation&&this.detaills.nation!=this.data_obj.nation){
                    this.up_obj.nation = this.data_obj.nation;
                    this.msg = this.msg+'民族'+' '
                    ii = 2
                }
                if(this.data_obj.native_place&&this.detaills.native_place&&this.detaills.native_place!=this.data_obj.native_place){
                    this.up_obj.native_place = this.data_obj.native_place;
                    this.msg = this.msg+'地址'+' '
                    ii = 2
                }
                if(this.data_obj.birthyear&&this.detaills.birthyear&&this.detaills.birthyear!=this.data_obj.birthyear){
                    this.up_obj.birthyear = this.data_obj.birthyear;
                    this.msg = this.msg+'生日'+' '
                    ii = 2
                }
                if(ii==1){
                    let data={
                        jobseeker_id_card:this.card_num
                    }
                    this.Request('client/mine/info', 'post',data,true,false).then(res => {
                        if (res.status == 0) {
                                this.$router.go(-1)
                        }                       
                        this.is_click = false
                    })  
                }else{
                    this.my_lizhi= true;
                }
            }
            

        },
        //取消
        cancle_no(){
            this.my_lizhi = false;
        },
        cancle_ok(){
            let data=this.up_obj
            data.jobseeker_id_card= this.card_num
                    this.Request('client/mine/info', 'post',data,true,false).then(res => {
                        if (res.status == 0) {
                                this.$router.go(-1)
                        }                       
                        this.is_click = false
                    }) 
        }
    }
}
</script>
<style scoped>
.img_fa{
    width: calc(100% - 60px);
    margin-left: 30px;
    padding-top: 30px;
    /* min-height: 235px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.img_fa_cd{
    width: 210px;
    height: 210px;
    margin-right: 26px;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 26px;
}
.img_fa>div:nth-child(3n){
  margin-right: 0 !important;
}
.cont_fa_on_rt2{
    width: calc(100% - 200px);
    height: 60px;
    line-height: 60px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.title_top_rt{
    width: 40px;
    height: 34px;
    background: url('../../assets/img/xiangji.png');
    background-size: 100% 100%;
}
.cont_fa{
    width: 100%;
    height: 110px;
    background: #ffffff;
}
.cont_fa_on{
    width: calc(100% - 80px);
    height: 110px;
    margin-left:40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.cont_fa_on_lt{
    width: 130px;
    height: 110px;
    line-height: 110px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1A1A1A;
}
.cont_fa_on_rt{
    width: calc(100% - 150px);
    height: 60px;
    line-height: 60px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.content_input{
    width:calc(100% - 80px);
    border-bottom: 2px solid #f8f8f8;
    height: 70px;
    margin-left: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}
.content_input input{
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.content_title{
    width:calc(100% - 80px);
    margin-left: 40px;
    margin-bottom: 22px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1A1A1A;
}
.btn_sty{
    width: calc(100% - 80px);
    margin: 120px auto;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background: #0ABBB9;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 1;
    border-radius: 10px;
}
.content {
    /* padding-top: 110px; */
    margin: 0 30px;
}
/* input输入框样式 */
.cell {
    display: flex;
    border-bottom: 2px solid #f8f8f8;
    padding: 18px 0;
    position: relative;
}
.cell .left {
    display: flex;
    width: 28%;
}
.cell .left .must {
    color: red;
    margin: 6px 10px 0 0;
    width: 10px;
}
.cell .left .title {
    min-width: 132px;
    text-align-last: justify;
}
.cell .right {
    width: 75%;
    display: flex;
    justify-content: space-between;
}
.cell .right input {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.cell .right img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
}
</style>